<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <div class="page-content pb-0">
      <div class="card rounded-m mb-0">
        <div class="content">
          <h4>{{ jobTitle }}</h4>
          <div class="mt-5 mb-3">
            <!-- Form -->
            <!-- Basic Information -->
            <p class="font-18 font-500 mb-3 color-highlight">Informasi Dasar</p>
            <div v-if="errors[0]" class="mb-3">
              <p class="color-red-dark">
                {{ errors[0].detail }}
              </p>
            </div>
            <div v-else-if="errors.errorMessage" class="mb-3">
              <p class="color-red-dark">
                {{ errors.errorMessage }}
              </p>
            </div>

            <div
              class="input-style no-borders no-icon validate-field"
              :class="{ 'input-style-active': title }"
            >
              <input
                type="name"
                class="form-control validate-name"
                id="form1"
                v-model="title"
                placeholder="Nama Pekerjaan"
                required
              />
              <label for="form1" class="">Nama</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!title"
                :class="{ 'color-red-dark font-800 req-val': errors.title }"
                >(required)</em
              >
            </div>

            <div
              class="input-style no-borders no-icon"
              :class="{ 'input-style-active': about }"
            >
              <textarea
                id="form1h"
                v-model="about"
                placeholder="Tentang Pekerjaan"
                required
              ></textarea>
              <label for="form1h" class="">Tentang Pekerjaan</label>
              <em
                v-if="!about"
                :class="{ 'color-red-dark font-800 req-val': errors.about }"
                >(required)</em
              >
            </div>

            <div
              class="input-style no-borders no-icon"
              :class="{ 'input-style-active': description }"
            >
              <textarea
                id="form1d"
                v-model="description"
                placeholder="Deskripsi Pekerjaan"
                required
              ></textarea>
              <label for="form1d">Deskripsi</label>
              <em
                v-if="!description"
                :class="{
                  'color-red-dark font-800 req-val': errors.description,
                }"
                >(required)</em
              >
            </div>

            <div
              class="input-style no-borders no-icon"
              :class="{ 'input-style-active': requirement }"
            >
              <textarea
                id="form1b"
                v-model="requirement"
                placeholder="Kualifikasi"
                required
              >
              </textarea>
              <label for="form1b" class="">Kualifikasi</label>
              <em
                v-if="!requirement"
                :class="{
                  'color-red-dark font-800 req-val': errors.requirement,
                }"
                >(required)</em
              >
            </div>

            <div
              class="input-style no-borders no-icon"
              :class="{ 'input-style-active': about }"
            >
              <textarea
                id="form1h"
                v-model="skill_desc"
                placeholder="Deskripsi keahlian yang di butuhkan"
                required
              ></textarea>
              <label for="form1h" class="">Keahlian</label>
              <em
                v-if="!skill_desc"
                :class="{
                  'color-red-dark font-800 req-val': errors.skill_desc,
                }"
                >(required)</em
              >
            </div>

            <div class="row">
              <label for="form1e">Gaji</label>
              <div
                class="input-style no-borders no-icon validate-field col-6"
                :class="{ 'input-style-active': salary_start }"
              >
                <input
                  type="name"
                  class="form-control validate-name"
                  id="form1e"
                  v-model="salary_start"
                  placeholder="minimal 10000"
                  required
                />
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <em
                  v-if="!salary_start"
                  :class="{
                    'color-red-dark font-800 req-val': errors.salary_start,
                  }"
                  >(required)</em
                >
              </div>

              <div
                class="input-style no-borders no-icon validate-field col-6"
                :class="{ 'input-style-active': salary_end }"
              >
                <input
                  type="name"
                  class="form-control validate-name"
                  id="form1f"
                  v-model="salary_end"
                  placeholder="misal 50000"
                  required
                />
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <em
                  v-if="!salary_end"
                  :class="{
                    'color-red-dark font-800 req-val': errors.salary_end,
                  }"
                  >(required)</em
                >
              </div>
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                validate-field
                input-style-active
              "
            >
              <input
                type="date"
                class="custom-form-control validate-name"
                id="form1i"
                v-model="expire_at"
                required
              />
              <label for="form1i" class="">Lowongan Berakhir</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!expire_at"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.expire_at }"
                >(required)</em
              >
            </div>
            <div class="d-flex mb-3">
              <div class="pt-1">
                <h5
                  data-data-trigger-switch="toggle-id-salary"
                  class="font-600 font-14"
                >
                  Sembunyikan Gaji
                  <p class="font-10 font-100 line-height-s">
                    *Gaji akan di sembunyikan
                  </p>
                </h5>
              </div>
              <div class="ms-auto me-3 pe-2">
                <div class="custom-control ios-switch small-switch">
                  <input
                    type="checkbox"
                    class="ios-input"
                    id="toggle-id-salary"
                    v-model="hide_salary"
                  />
                  <label
                    class="custom-control-label"
                    for="toggle-id-salary"
                  ></label>
                </div>
              </div>
            </div>
            <!--End Basic Information -->

            <!-- Requirement -->
            <p class="font-18 font-500 mb-3 mt-5 color-highlight">Kategori</p>

            <!-- Category -->

            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
              "
            >
              <select class="form-control" id="form1bc" v-model="categoryL1">
                <option value="default" disabled selected>
                  Kategori Level 1
                </option>
                <option
                  v-for="category in category.level1"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
              <label for="form1bc">Kategori Level 1</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!categoryL1"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.category }"
                >(required)</em
              >
              <p class="font-10 font-100 line-height-s">
                * Kategori Umum, misal Akuntansi dan Keuangan
              </p>
            </div>
            <!-- Category level 2 -->

            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
                mt-4
              "
            >
              <select
                v-if="!addLevel2"
                class="form-control"
                id="form1bd"
                v-model="categoryL2"
              >
                <option value="default" disabled selected>
                  Kategori Level 2
                </option>
                <option
                  v-for="category in category.level2"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
                <option value="addL2">Tambah kategori baru</option>
              </select>

              <input
                v-if="addLevel2"
                type="name"
                class="form-control validate-name"
                id="form1bd"
                v-model="categoryL2"
                placeholder="Tambah kategori level 2"
                required
              />
              <label for="form1bd">Kategori level 2</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!categoryL2"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.category }"
                >(required)</em
              >
              <p class="font-10 font-100 line-height-s">
                * Sub kategori, misal Kasir
              </p>
            </div>

            <!-- Category level 3 -->
            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
                mt-4
              "
            >
              <select
                v-if="!addLevel3"
                class="form-control"
                id="form1be"
                v-model="categoryL3"
              >
                <option value="default" disabled selected>
                  Kategori Pekerjaan
                </option>
                <option
                  v-for="category in category.level3"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
                <option value="addL3">Tambah kategori baru</option>
              </select>

              <input
                v-if="addLevel3"
                type="name"
                class="form-control validate-name"
                id="form1be"
                v-model="categoryL3"
                placeholder="Tambah kategori pekerjaan"
                required
              />
              <label for="form1be">Kategori</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <p class="font-10 font-100 line-height-s">
                *Optional, Kategori spesifik, misal Staf kasir
              </p>
            </div>

            <!-- End Category -->
            <!-- Skill -->
            <div class="mb-3 mt-3">
              <label for="form1bef">Skills</label>
              <Multiselect
                v-model="skills"
                mode="tags"
                :filter-results="false"
                :min-chars="1"
                :resolve-on-load="false"
                :delay="0"
                :searchable="true"
                :options="
                  async function (query) {
                    return await fetchSkill(query);
                  }
                "
              />
            </div>
            <!-- End Skill -->
            <div
              class="input-style no-borders no-icon validate-field mb-4"
              :class="{ 'input-style-active': job_type }"
            >
              <select class="form-control" id="form1a" v-model="job_type">
                <option
                  v-for="type in options.job_type"
                  :key="type.value"
                  :value="type.value"
                  disabled
                >
                  {{ type.label }}
                </option>
              </select>
              <label for="form1a" class="">Tipe Pekerjaan</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!job_type"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.job_type }"
                >(required)</em
              >
            </div>
            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
              "
            >
              <select
                class="form-control"
                v-if="job_type === 'VC'"
                id="form1a"
                v-model="level"
              >
                <option value="default" disabled="" selected="">
                  Select a Value
                </option>
                <option
                  v-for="level in master_data.job_level"
                  :key="level.id"
                  :value="level.id"
                >
                  {{ level.name }}
                </option>
              </select>
              <label for="form1a">Level</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!level"
                :class="{ 'color-red-dark font-800 req-val': errors.level }"
                >(required)</em
              >
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
              "
            >
              <select class="form-control" id="form1g" v-model="worktime">
                <option value="default" disabled="">Select a Value</option>
                <option
                  v-for="worktime in options.worktime"
                  :key="worktime.value"
                  :value="worktime.value"
                  :disabled="job_type === 'PJ'"
                >
                  {{ worktime.label }}
                </option>
              </select>
              <label for="form1h">Waktu kerja</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!worktime"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.worktime }"
                >(required)</em
              >
            </div>
            <div class="d-flex mb-3">
              <div class="pt-1">
                <h5
                  data-data-trigger-switch="toggle-id-2"
                  class="font-600 font-14"
                >
                  Remote
                  <p class="font-10 font-100 line-height-s">
                    *Pekerjaan dilakukan secara daring
                  </p>
                </h5>
              </div>
              <div class="ms-auto me-3 pe-2">
                <div class="custom-control ios-switch small-switch">
                  <input
                    type="checkbox"
                    class="ios-input"
                    id="toggle-id-2"
                    v-model="remote"
                  />
                  <label class="custom-control-label" for="toggle-id-2"></label>
                </div>
              </div>
            </div>
            <!--End Requirement -->

            <!-- Location -->
            <p class="font-18 font-500 mb-3 mt-5 color-highlight">
              Lokasi Bekerja
            </p>

            <div
              class="input-style no-borders no-icon mb-4"
              :class="{ 'input-style-active': work_loc_address }"
            >
              <textarea
                id="form1b"
                v-model="work_loc_address"
                required
                maxlength="150"
                placeholder="Lokasi pekerjaan"
              ></textarea>
              <label for="form1b">Alamat</label>
              <em
                v-if="!work_loc_address"
                :class="{
                  'color-red-dark font-800 req-val': errors.work_loc_address,
                }"
                >(required)</em
              >
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
              "
            >
              <select
                class="form-control"
                id="form1a"
                v-model="work_loc_country"
              >
                <option value="default" disabled="" selected="">
                  Select a Value
                </option>
                <option
                  v-for="country in master_data.country"
                  :key="country.id"
                  :value="country.id"
                >
                  {{ country.name }}
                </option>
              </select>
              <label for="form1a">Negara</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!work_loc_country"
                class="me-3"
                :class="{
                  'color-red-dark font-800 req-val': errors.work_loc_country,
                }"
                >(required)</em
              >
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
              "
            >
              <select
                class="form-control"
                id="form1a"
                v-model="work_loc_province"
              >
                <option value="default" disabled="" selected="">
                  Select a Value
                </option>
                <option
                  v-for="province in master_data.province"
                  :key="province.id"
                  :value="province.id"
                >
                  {{ province.name }}
                </option>
              </select>
              <label for="form1a">Provinsi</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!work_loc_province"
                class="me-3"
                :class="{
                  'color-red-dark font-800 req-val': errors.work_loc_province,
                }"
                >(required)</em
              >
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
              "
            >
              <select class="form-control" id="form1a" v-model="work_loc_city">
                <option value="default" disabled="" selected="">
                  Select a Value
                </option>
                <option v-for="city in cities" :key="city.id" :value="city.id">
                  {{ city.name }}
                </option>
              </select>
              <label for="form1a">Kota</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!work_loc_city"
                class="me-3"
                :class="{
                  'color-red-dark font-800 req-val': errors.work_loc_city,
                }"
                >(required)</em
              >
            </div>
            <!-- End location -->
          </div>
          <a
            @click.prevent="submitForm"
            href="#"
            class="
              btn btn-full btn-m
              gradient-highlight
              rounded-s
              font-13 font-600
              mt-4
            "
            >Simpan</a
          >
          <router-link
            :to="{ name: 'Dashboard' }"
            href="#"
            class="
              btn btn-full btn-m
              bg-red-dark
              rounded-s
              font-13 font-600
              mt-4
            "
            >Batal</router-link
          >
        </div>
      </div>
    </div>
    <!-- Page content ends here-->
    <div
      id="create-job-modals"
      class="menu menu-box-modal rounded-m"
      data-menu-hide="1500"
      data-menu-width="250"
      data-menu-height="170"
    >
      <h1 class="text-center mt-3 pt-2">
        <i class="fa fa-check-circle scale-icon fa-3x color-green-dark"></i>
      </h1>
      <h3 class="text-center pt-2">Berhasil di tambahkan</h3>
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
/*import "../assets/code/scripts/custom.js";*/
import Header from "@/components/Header.vue";
import Multiselect from "@vueform/multiselect";
export default {
  name: "FormJob",
  components: {
    Header,
    Multiselect,
  },
  inject: ["getMasterData"],
  data() {
    return {
      title: "",
      job_type: "VC",
      level: "",
      work_loc_address: "",
      requirement: "",
      work_loc_country: "",
      work_loc_province: "",
      work_loc_city: "",
      work_loc_gps: "",
      description: "",
      salary_start: "",
      salary_end: "",
      worktime: "",
      remote: false,
      hide_salary: false,
      skill_desc: "",
      tags: "",
      about: "",
      expire_at: "",
      cities: [],
      categoryL1: "",
      categoryL2: "",
      categoryL3: "",
      newCategory: "",
      skills: [],

      options: {
        worktime: [
          { value: "FT", label: "Full Time" },
          { value: "PT", label: "Part Time" },
          { value: "FL", label: "Freelance" },
          { value: "IT", label: "Internship" },
        ],
        job_type: [
          { value: "VC", label: "Lowongan" },
          { value: "PJ", label: "Freelance" },
        ],
      },

      allCategory: [],
      category: {
        level1: [],
        level2: [],
        level3: [],
      },
      addLevel2: false,
      addLevel3: false,
      master_data: {},
      dataJob: "",
      errors: [],
      isLoading: true,
      jobTitle: "Tambah Lowongan",
      submit: false,
    };
  },
  watch: {
    work_loc_province() {
      this.cities = this.master_data.province.filter((item) => {
        return item.id === this.work_loc_province;
      });
      this.cities = this.cities[0].city;
    },

    categoryL1() {
      this.category.level2 = this.allCategory.filter((item) => {
        return item.parent === this.categoryL1;
      });
      this.addLevel2 = false;
      this.addLevel3 = false;
    },

    categoryL2() {
      if (!(this.categoryL2 === "addL2") && !(this.categoryL2 === "")) {
        this.category.level3 = this.allCategory.filter((item) => {
          return item.parent === this.categoryL2;
        });
      }
      if (this.categoryL2 === "addL2") {
        this.addLevel2 = true;
        this.categoryL2 = "";
      }
      this.addLevel3 = false;
    },

    categoryL3() {
      if (this.categoryL3 === "addL3") {
        this.addLevel3 = true;
        this.categoryL3 = "";
      }
    },
  },
  mounted() {
    init_template();
    this.getCategory();
    this.master_data = this.$store.state.master_data;
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
    if (this.$route.query.jobType === "project") {
      this.jobTitle = "Tambah Freelance";
      this.job_type = "PJ";
      this.worktime = "FL";
      this.remote = true;
    }
  },
  methods: {
    menuClose,
    menuOpen,
    currentDate() {
      const current = new Date();
      const date =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        current.getDate();
      /*const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();*/

      return date;
    },
    async fetchSkill(query) {
      let queryData = [];
      await axios
        .get("/api/v1/employer/skill/?search=" + query)
        .then((response) => {
          queryData = response.data;
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error));
          }
        });
      return queryData.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    },
    async getCategory() {
      await axios
        .get("api/v1/employer/category/?all_category=true")
        .then((response) => {
          this.allCategory = response.data;
          this.category.level1 = response.data.filter((item) => {
            return item.level === "L1";
          });
          /*this.category.level2 = response.data.filter((item) => {*/
          /*return item.level === "L2";*/
          /*});*/
          /*this.category.level3 = response.data.filter((item) => {*/
          /*return item.level === "L3";*/
          /*});*/
          /*console.log("category", this.category);*/
        })
        .catch((error) => {
          this.errors = [];
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
            /*import "../assets/code/scripts/custom.js";*/
            this.isLoading = false;
            this.submit = false;
          }
        });
    },
    validateInput() {
      this.errors = {};
      var dataInputJob = {
        title: this.title,
        job_type: this.job_type,
        category: this.categoryL3,
        skill_desc: this.skill_desc,
        work_loc_address: this.work_loc_address,
        requirement: this.requirement,
        work_loc_country: this.work_loc_country,
        work_loc_province: this.work_loc_province,
        work_loc_city: this.work_loc_city,
        description: this.description,
        salary_start: this.salary_start.toString().split(".").join(""),
        salary_end: this.salary_end.toString().split(".").join(""),
        worktime: this.worktime,
        remote: this.remote,
        hide_salary: this.hide_salary,
        about: this.about,
        expire_at: this.expire_at,
        skills: this.skills,
      };

      if(!this.categoryL3){
        dataInputJob.category = this.categoryL2
      }

      //validate pref salary  input
      if (dataInputJob.salary_start < 10000) {
        this.errors["pref_salary_start"] = "Tidak boleh kurang dari 10000";
        this.errors["errorMessage"] = "Tidak boleh kurang dari 10000";
      }

      if (
        parseInt(dataInputJob.salary_start) > parseInt(dataInputJob.salary_end)
      ) {
        this.errors["pref_salary_start"] =
          "Tidak boleh lebih dari gaji terakhir";
        this.errors["errorMessage"] =
          "Gaji awal tidak boleh lebih dari gaji terakhir";
      }

      if (this.job_type === "VC") {
        dataInputJob["level"] = this.level;
      }
      for (const key in dataInputJob) {
        if (dataInputJob[key] === "") {
          this.errors[key] = "empty";
          this.errors["errorMessage"] =
            "Pastikan yang bertanda merah tidak kosong";
        }
      }
      if (Object.keys(this.errors).length) {
        console.log(this.errors);
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
      this.dataJob = dataInputJob;
    },
    async createJob() {
      const formData = this.dataJob;
      formData.category = this.newCategory;
      await axios
        .post("/api/v1/employer/job/", formData)
        .then((response) => {
          this.isLoading = false;
          var job_type = this.job_type === "VC" ? "vacancy" : "project";
          this.menuOpen("create-job-modals");
          setTimeout(() => {
            this.$router.push({
              name: "CreateJob",
              params: { job_type: job_type },
            });
          }, 2000);
        })
        .catch((error) => {
          this.errors = [];
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
          this.submit = false;
          this.isLoading = false;
          console.log(this.submit);
        });
    },
    async createCategory() {
      this.isLoading = true;
      var formDataCategory = {
        category_level1: this.categoryL1,
        category_level2: this.categoryL2,
        category_level3: this.categoryL3,
      };

      await axios
        .post("api/v1/employer/create-category/", formDataCategory)
        .then((response) => {
          console.log('response : ', response);
          this.newCategory = response.data.id;
          this.createJob();
        })

        .catch((error) => {
          this.errors = [];
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push({ detail: `${error.response.data[property]}` });
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
            /*import "../assets/code/scripts/custom.js";*/
            this.isLoading = false;
            this.submit = false;
          }
        });
      this.isLoading = false;
    },
    async submitForm() {
      this.validateInput();
      if (!Object.keys(this.errors).length && !this.submit) {
        this.isLoading = true;
        this.submit = true;
        if (this.addLevel3 || this.addLevel2) {
          this.createCategory();
        } else {
          this.createJob();
        }
      }
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
.multiselect.multiselect-search {
  margin: 5px 0px !important;
}
.multiselect-tag.is-user {
  padding: 5px 8px;
  border-radius: 22px;
  background: #35495e;
  margin: 3px 3px 8px;
}

.multiselect-tag.is-user img {
  width: 18px;
  border-radius: 50%;
  height: 18px;
  margin-right: 8px;
  border: 2px solid #ffffffbf;
}

.multiselect-tag.is-user i:before {
  color: #ffffff;
  border-radius: 50%;
}
</style>
<style>
.custom-form-control {
  background-color: #fff;
  color: #212529;
  border: 1px solid #ced4da;
}
</style>
